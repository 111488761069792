import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {HttpInterceptor} from '@angular/common/http';
import {MatTreeModule} from '@angular/material/tree';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
// Bootstrap DataTable

import {ToastrModule} from 'ngx-toastr';
import {Router} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {AuthService} from './services/auth.service';
import {MatButtonModule} from '@angular/material/button';
import {LoginComponent} from './login/login/login.component';
import {ResetpasswordComponent} from './login/reset/resetpassword.component';
import {RegisterComponent} from './login/register/register.component';
import {DashboardComponent} from './all-modules/dashboard/dashboard.component';
import {TokenInterceptorService} from './token-interceptor.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SpinnerComponent} from './snipper/spinner-component';
import { TimeFormatPipe } from './time-format.pipe';
import{ForgotComponent} from "./login/forgot/forgot.component";
import{NewpasswordComponent} from "./login/newpassword/newpassword.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
SpinnerComponent,
TimeFormatPipe,
        ResetpasswordComponent,ForgotComponent,NewpasswordComponent,

    ],
    imports: [

        MatTreeModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        ProgressSpinnerModule,
        HttpClientModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            timeOut: 1000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            closeButton: true,
        }), HttpClientModule, FontAwesomeModule,


    ],
    providers: [AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        }

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

